<template>
  <div class="box">
    <header>
      <router-link
        :to="
          '/jiyun_province?provinceName=' +
            provinceName +
            '&provinceCode=' +
            provinceCode
        "
      >
        <img
          class="arrow-left"
          src="../../../src/assets/usian/arrow-right.png"
        />
      </router-link>
      <img src="../../../src/assets/usian/bg_china_head.png" alt="头部背景图" />
    </header>

    <div class="main">
      <div class="left">
        <div class="section">
          <div ref="xinzi" class="xinzi"></div>
        </div>
        <div class="section">
          <div ref="jiuye" class="jiuye"></div>
        </div>
      </div>
      <div class="right" ref="china">
        <!-- <div>
          <p>教育资源总量</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
let myChart = null;
export default {
  data() {
    return {
      salaryRank: [], //历年薪资排行
      employRank: [], //就业排行
      provinceName: "", //省市名称
      provinceCode: 0, //省代码
      mapJson: {}, //地图json
      mapData: [], //地图数组
    };
  },
  mounted() {
    this.provinceName = this.$route.query.provinceName;
    this.provinceCode = this.$route.query.provinceCode;

    axios
      .get("/api/job/yearsRanking")
      .then((res) => {
        res = res.data;
        this.salaryRank = res.data.years_salary;
        this.employRank = res.data.job_ranking;

        this.initXinzi();
        this.initJiuye();
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
    this.initChina();
  },
  methods: {
    initXinzi() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.xinzi);

      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          data: this.salaryRank.map((item) => item.year),
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          data: [
            {
              name: "积云历年薪资(单位:千元)",
              textStyle: {
                color: "#fff",
              },
            },
          ],
        },
        tooltip: {},
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: 55,
        },
        series: [
          {
            name: "积云历年薪资(单位:千元)",
            type: "bar",
            data: this.salaryRank.map((item) => item.salary),
          },
        ],
        itemStyle: {
          color: "#01f6f8",
        },
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        //浏览器大小调整echarts随之改变
        myChart.resize();
      });
    },
    initJiuye() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.jiuye);

      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          data: this.employRank.map((item) => item.city),
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          data: [
            {
              name: "就业排行",
              textStyle: {
                color: "#fff",
              },
            },
          ],
        },
        tooltip: {},
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: 55,
        },
        series: [
          {
            name: "就业排行",
            type: "bar",
            data: this.employRank.map((item) => item.num),
          },
        ],
        itemStyle: {
          color: "#01f6f8",
        },
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        //浏览器大小调整echarts随之改变
        myChart.resize();
      });
    },

    initChina() {
      myChart = this.$echarts.init(this.$refs.china);
      myChart.showLoading();

      axios
        .get("/pc/geoJson/100000.geoJson")
        .then((response) => {
          myChart.hideLoading();
          let data = response.data;
          let mapJsonList = data.features;
          let mapJson = {};
          let mapData = [];
          for (let i in mapJsonList) {
            mapData.push({
              name: mapJsonList[i].properties.name,
              adcode: mapJsonList[i].properties.adcode,
              value: Math.random() * 100000,
              selected: i == 0 ? true : false,
            });
          }
          mapJson.features = mapJsonList;
          this.mapJson = mapJson;
          this.mapData = mapData;
          this.loadMap("china", mapJson, mapData); //加载地图数据
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },
    loadMap(mapName, data, mapData) {
      if (data) {
        this.$echarts.registerMap(mapName, data);
        var option = {
          visualMap: {
            //在展示每个区域数据量场景使用，不同的范围区域颜色调节
            min: 50000,
            max: 800,
            inRange: {
              color: ["#f50d34", "#003095", "#2e7ac4", "#4060c6"],
            },
            text: ["High", "Low"], // 文本，默认为数值文本
            calculable: false,
            show: false,
          },
          series: [
            {
              type: "map",
              mapType: "china", // 自定义扩展图表类型
              label: {
                show: true,
                color: "#fff",
              },
              data: mapData,
              zoom: 1.25,
            },
          ],
        };
        myChart.setOption(option);

        window.addEventListener("resize", () => {
          //浏览器大小调整echarts随之改变
          myChart.resize();
        });

        myChart.on("click", (params) => {
          //地图区域点击时的联动   根据params的值来联动对应数据
          console.log("params", params); //此处写点击事件内容
          this.$router.push(
            "/jiyun_province?provinceName=" +
              params.data.name +
              "&provinceCode=" +
              params.data.adcode
          );
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: url(../../../src/assets/usian/bg_china.jpg) center no-repeat;
  background-size: cover;
  text-align: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  position: relative;
  display: flex;
  justify-content: center;
  img {
    height: 5.52vw;
  }
  .arrow-left {
    position: absolute;
    top: 0;
    left: 40px;
    width: auto;
    height: 2.08vw;
    transform: rotate(180deg);
  }
}

.main {
  padding: 2.29vw;
  display: flex;
  flex: 1;
}

.left {
  width: 16.83vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right {
  flex: 1;
}

.section {
  width: 12.83vw;
  height: 12.83vw;
  background: url(../../../src/assets/usian/kuang_big.png) center no-repeat;
  background-size: cover;
  padding: 2vw;
}

.section:first-child {
  margin-bottom: 3.13vw;
}

.xinzi,
.jiuye {
  width: 100%;
  height: 100%;
}
</style>
